body{
    background-color: black;
}
#container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    /* background-color: #2c2b2b; */
    border-radius: 30px;
    margin: 0 auto;
    margin-top: 40px;
    transition: transform 0.3s ease;
}
#container:hover{
    transform: scale(1.02);
}
#why{
    color: white;
    width: 50%;
    padding: 50px;
    text-align: center;
}
#why h1{
    margin: 0;
    margin-bottom: 50px;
    font-size: 40px;
}
#why p{
    margin: 10px;
    font-size: 15px;
}
#logo{
    height: 350px;
    width: 300px;
    /* background-color: #BBEBEF;
    color: black; */
    margin: 20px;
    /* text-align: center; */
}
@media (max-width: 700px){
    #why h1{
        font-size: 35px;
    }
    /* #why p{
        font-size: 12px;
    } */
    #logo{
        display: none;
    }
}