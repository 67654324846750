/* Default - Mobile First */
.sponsor-carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #121212; */
    padding: 1rem;
    margin: 0 auto;
    justify-content: center;
  }
  
  .sponsor-heading {
    color: white;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .sponsor-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #2c2b2b;
    border-radius: 15px;
    width: 75%;
    padding: 1rem;
    color: white;
    transition: transform 0.3s ease;
  }
  .sponsor-card:hover{
    transform: scale(1.02);
 }
  .sponsor-content {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .sponsor-logo {
    width: 150px;
    height: auto;
    margin-bottom: 1rem;
  }
  .nav-button{
    transition: transform 0.3s ease;
  }
  .nav-button:hover{
    transform: scale(1.5);
  }
  
  .sponsor-content h2 {
    font-size: 1.2rem;
  }
  
  .sponsor-content p {
    font-size: 1rem;
  }
  
  .nav-button {
    background: none;
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    padding: 0.5rem;
  }
  #section3-heading{
    font-size: 3rem;
  }

  #sponsor_image{
    height: 8rem;
    width: auto;
  }
  /* Larger Screens - Tablet and Desktop */
  @media (min-width: 768px) {
    .sponsor-card {
      flex-direction: row;
      justify-content: space-between;
      max-width: 80%;
    }
  
    .sponsor-content {
      text-align: left;
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .sponsor-logo {
      width: 120px;
      margin-right: 1rem;
      margin-bottom: 0;
    }
  
    .nav-button {
      font-size: 2.5rem;
      padding: 1rem;
    }
  }
  
  @media (min-width: 1024px) {
    .sponsor-card {
      max-width: 70%;
    }
  
    .sponsor-heading {
      font-size: 2rem;
    }
  
    .sponsor-content h2 {
      font-size: 1.5rem;
    }
  
    .sponsor-content p {
      font-size: 1.1rem;
    }
  
    .sponsor-logo {
      width: 150px;
    }
  }