
#event-page-container {
    background-color: #121212;
    color: #fff; 
    min-height: 100vh; 
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#event-heading{
    text-align: center;
    padding: 10px 2rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 25px;
    background-color: black;
    width: 85%;
    transition: transform 0.3s ease;
}

#event-heading:hover {
    transform: scale(1.02);
}

.eventpage-card {
    background-color: #1e1e1e; 
    padding: 2rem;
    border-radius: 20px;
    margin: 1rem;
    width: 85%; 
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
}


.eventpage-card h1{
    font-size: 2.5rem;
}
.eventpage-card p{
    font-size: 1.3rem;
}


#event-card-title {
    font-size: 3rem;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 2rem;
    text-align: center;
    text-shadow: -2px 0px black, 0px 2px black, 2px 0px black, 0px -2px black;
}

#event-card-tagline {
    text-shadow: -1px 0px black, 0px 1px black, 1px 0px black, 0px -1px black;
}

.eventpage-card:hover {
    transform: scale(1.02);
}