.App{
    /* background-color: #121212; */
    background-image: linear-gradient(rgb(0, 0, 0), rgb(21, 21, 21));
    padding-top: 2rem;
}
#landing-21-container{
    padding-top: 2rem;
}
#landing-s1-inner-container{
    /* background-image: url(/src/screens/LandingScreen/hacks_bg.png); */
    /* background-image: url(/src/screens/LandingScreen/Bkg-Images/smoke_background.png); */
    background-image: url(./../xavage-animated-gif.gif);
    background-position: center;
    background-size: contain;
    height: 600px;
    background-size: cover;
    margin: 1rem 2rem;
    max-width: 100%;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
}
#xavage-heading{
    color: #fff;
    font-size: 4rem;
    font-weight: 2rem;
    margin: 1rem;
}
#edition-heading{
    color: #fff;
    font-size: 3rem;
    font-weight: 2rem;
    margin: 1.5rem 1rem;
}
#registration-button{
    margin: 1rem;
    padding: 1rem;
    width: 50%;
    height: 5rem;
    border: none;
    border-radius: 50px;
    background-color: #83F4FF;
    font-size: 1rem;
    color: #000000;
    text-align: center;
    font-weight: 700;
    transition: transform 0.3s ease;
}
#registration-button:hover{
    transform: scale(1.02);
}

@media (min-width: 700px){
    #xavage-heading{
        font-size: 6rem;
    }
    #edition-heading{
        font-size: 4.5rem;
    }
    #registration-button{
        font-size: 2rem;
        width: 35%;
    }
}