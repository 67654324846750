#landing-s2-section{
    /* background-color:#121212; */
    margin: 0rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.event-heading {
    text-shadow: -2px 0px black, 0px 2px black, 2px 0px black, 0px -2px black;
}
.event-subheading{
    text-shadow: -1px 0px black, 0px 1px black, 1px 0px black, 0px -1px black;
    margin-top: 2rem;
    text-decoration: none;
}

#landing-s2-section h1, h2, h2, h3, h4, p{
    color: #fff;
}
#landing-s2-top{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}
#landing-s2-text{
    text-wrap: wrap;
    max-width: 75%;
}
#landing-s2-button{
    margin: 1rem;
    padding: 1rem;
    width: 70%;
    border: none;
    border-radius: 10px;
    background-color: #83F4FF;
    color: #000;
    font-size: 1.2rem;
    font-weight: 650;
    transition: transform 0.3s ease;
}
#landing-s2-button:hover{
    transform: scale(1.02);
}
#landing-s2-heading{
    font-size: 3rem;
}

#landing-s2-bottom{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}
#s2-event-card{
    margin: 0.5rem 2rem;
    padding: 0.5rem;
    border-radius: 25px;
    background-color: #000000;
    width: 80%;
    height: 160px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    transition: transform 0.3s ease;
}
#s2-event-card:hover{
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}
#s2-event-card-title{
    text-decoration: none;
    color: #fff;
    font-size: 2rem;
    font-weight: 750;
    margin-top: 1rem;
}
#s2-event-card-desc{
    font-size: 1rem;
    font-weight: 500;
}

#landing-s2-visitingpage{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 650px){

    #landing-s2-top{
        justify-content: center;
        align-items: center;
    }
    #landing-s2-text{
        text-wrap: wrap;
        max-width: 75%;
    }
    #landing-s2-top h1{
        font-size: 4rem;
        margin-bottom: 1rem;
    }
    #landing-s2-top p{
        font-size: 1.5rem;
    }
    #landing-s2-button{
        width: 40%;
        margin-bottom: 2rem;
        height: 60px;
        font-size: 1.35rem;
        font-weight: 600;
    }

    #landing-s2-bottom{
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;

    }
    #s2-event-card{
        margin: 1rem 0.5rem;
        padding: 0.5rem;
        width: 20%;
        height: 275px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    #landing-s2-bottom > :nth-child(n+5):nth-child(-n+7){
        width: 27.5%;
    }
    #s2-event-card-title{
        font-size: 2.2rem;
        text-wrap: wrap;
    }
    #s2-event-card-desc{
        font-size: 1.2rem;
    }
}